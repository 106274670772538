import React from "react";
import '/src/assets/css/RegisterDomains/domain-tips.css'
import domainImg from '/src/assets/images/register-domains/domain-search.png'
import oneImg from '/src/assets/images/register-domains/1.png'
import twoImg from '/src/assets/images/register-domains/2.png'
import threeImg from '/src/assets/images/register-domains/3.png'
import fourImg from '/src/assets/images/register-domains/4.png'

const DomainTips = () => {
    return (
        <section id='domain-tips'>
            <div className="container">
                <div className="row">

                    <div className="col-12 text-center mb-5">
                        <h3 className='h3-title main-color'>Află cum să alegi cel mai potrivit nume pentru domeniul
                            tău</h3>
                    </div>

                    <div className="col-lg-5 d-lg-flex col-12 d-none">
                        <div className="d-flex h-100 align-items-center">
                            <img src={domainImg} alt="Domain Tips" className='domain-img img-fluid' loading="lazy"/>
                        </div>
                    </div>

                    <div className="col-lg-7 col-12">

                        <div className="d-flex flex-row align-items-center mb-5">
                            <img src={oneImg} alt="Unu" className='img-fluid point-number mr-4' loading="lazy"/>
                            <p className='point-text'>Pentru ca publicul țintă să rețină numele site-ului tău alege unul
                                cât mai scurt și ușor de reținut sau scris, astfel încât să fie găsit rapid inclusiv la
                                cea mai simplă căutare în Google.</p>
                        </div>

                        <div className="d-flex flex-row align-items-center mb-5">
                            <img src={twoImg} alt="Doi" className='img-fluid point-number mr-4' loading="lazy"/>
                            <p className='point-text'>Alege un TLD reprezentativ. Dacă te adresezi exclusiv pieței din
                                România, optează pentru extensia .RO sau orientează-te către .COM și abia apoi către
                                alte variante mai speciale.
                            </p>
                        </div>

                        <div className="d-flex flex-row align-items-center mb-5">
                            <img src={threeImg} alt="Trei" className='img-fluid point-number mr-4' loading="lazy"/>
                            <p className='point-text'>Evită numerele, cratimele sau cuvintele scrise diferit față de
                                normal pentru a înlătura confuziile și acele situații în care vizitatori tăi nu reușesc
                                să tasteze facil numele domeniului.
                            </p>
                        </div>

                        <div className="d-flex flex-row align-items-center mb-5">
                            <img src={fourImg} alt="Patru" className='img-fluid point-number mr-4' loading="lazy"/>
                            <p className='point-text'>Alege un nume de domeniu unic, fără a mai ține cont de expresii
                                cheie Exact Match (ex: pantofi-damă.ro).Această practică nu mai reprezintă un avantaj
                                SEO pentru motoarele de căutare.</p>
                        </div>

                        <p>Ai nevoie de mai multe sfaturi în alegerea numelui de domeniu? Specialiștii SiteBunker.ro în
                            înregistrare domenii îți stau la dispoziție 24/7,
                            <a href="https://sitebunker.ro/client/submitticket.php?step=2&deptid=3"
                               className='ml-2 font-weight-bolder' target="_blank">prin serviciul de ticketing
                                disponibil aici</a>. </p>


                    </div>

                </div>
            </div>
        </section>
    )
}

export default DomainTips