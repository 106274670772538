import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import BenefitInfo from "../components/Common/BenefitInfo";
import PaymentsAccepted from "../components/Common/PaymentsAccepted";
import RegisterDomainsIntro from "../components/RegisterDomains/RegisterDomainsIntro";
import DomainTips from "../components/RegisterDomains/DomainTips";
import SimpleFAQS from "../components/Common/SimpleFAQS";
import SEO from "../components/App/SEO";
import '/src/assets/css/RegisterDomains/register-domains-benefits.css'

// SVG
import SvgSearch from "/src/assets/svgComponents/search.svg"
import SvgRedirect from "/src/assets/svgComponents/redirect.svg"
import SvgInfinite from "/src/assets/svgComponents/inifinite.svg"
import SvgFile from "/src/assets/svgComponents/file-text.svg"
import SvgServerRefresh from "/src/assets/svgComponents/server-refresh.svg"
import SvgLock from "/src/assets/svgComponents/lock.svg"
import SvgRefresh from "/src/assets/svgComponents/refresh.svg"


const faqs = [
    {
        title: 'Ce inseamna TLD?',
        text1: 'TLD sau Top Level Domain reprezintă extensia aleasă pentru domeniul tău. Practic se referă la ultimele caractere ale domeniului. De exemplu, la domeniul SiteBunker.ro, ".ro" reprezintă TLD-ul sau extensia. Poți alege din peste 50 de posibilități de TLD-uri și îți recomandăm să faci asta în funcție de piața către care te adresezi (Ex: Pentru piața internațională se potrivește mai mult .COM față de .RO)\n',
    },
    {
        title: 'Cum pot schimba numele de domeniu după ce l-am cumparat?',
        text1: 'Diferența dintre numele de domeniu și pachetul de găzduire este următoarea: 1.Domeniul reprezintă adresa unei locații pe Internet care ajută utilizatorii să acceseze anumite site-uri 2.Găzduirea este practic spațiul fizic în care conținutul unui website este salvat și publicat pentru a permite accesarea lui prin intermediul internetului.',
    },
    {
        title: 'Pot cumpăra un domeniu fără să achiziționez și un pachet de găzduire?',
        text1: 'Achiziționarea unui domeniu nu presupune automat să achiziționezi inclusiv un pachet de găzduire. La SiteBunker.ro ai posibilitatea să efectuezi oricâte înregistrări de domenii dorești și să le găzduiești fie tot la noi, ori la orice alt provider preferat.',
    },
    {
        title: 'Cât durează înregistrarea unui domeniu?',
        text1: 'În cele mai frecvente cazuri, înregistrarea și activarea unui domeniu este instantă. Imediat ce ai finalizat procesul de comandă, inclusiv plata a fost confirmată și ai introdus toate datele necesare înregistrării, domeniul tău va fi activ.\n',
    },
    {
        title: 'Cum pot transfera un domeniu la SiteBunker.ro?',
        text1: 'Pentru a transfera la SiteBunker.ro un domeniu achiziționat de la orice alt registrar este necesar să  obții Cheia de Autorizare a transferului. Cu ajutorul acesteia, transferul se poate realiza în doar câteva secunde.',
    },
    {
        title: 'Ce reprezintă DNS?',
        text1: 'DNS-ul sau  "domain name system" este standardul utilizat în gestionarea și administrarea adreselor IP ale tuturor site-urilor din întreaga lume. În momentul în care accesezi un domeniu în orice browser, acesta interoghează serverul DNS și afișează instant conținutul.',
    },
    {
        title: 'Pot schimba numele domeniului după achiziție?',
        text1: 'Odată achiziționat, un nume de domeniu nu mai poate fi schimbat. Poți alege însă să achiziționezi un nou nume de domeniu, iar cel inițial să nu mai fie prelungit la data de expirare. Nu uita însă că ai inclusiv posibilitatea să redirectezi domeniul inițial către noul domeniu, pentru a nu pierde eventualele vizite efectuate de utilizatorii deja obișnuiți cu vechiul domeniu.',
    },
    {
        title: 'Ce se întâmplă dacă uit să reînnoiesc numele de domeniu?',
        text1: 'În funcție de perioada de valabilitate achiziționată, un domeniu necesită reînnoire la 1 anumit interval de timp (Ex: 1 an). Dacă din varii motive ai omis să-l reînnoiești, reține că există o perioadă de grație de aproximativ 30-90 zile, în funcție de extensia aleasă, în care mai poți recupera domeniul, însă va fi necesar să achiți contravaloarea unei taxe de recuperare, care este mult mai mare decât prețul înregistrării sau al prelungirii domeniului, plus taxa de reînnoire. În situația în care ai depășit inclusiv perioada de grație alocată, domeniul va deveni disponibil pentru un nou potențial cumpărator și nu va mai fi în proprietatea ta.',
    },
    {
        title: 'Ce înseamnă mai exact Whois Privacy?',
        text1: 'În momentul în care alegi să achiziționezi Whois Privacy, practic te asiguri că toate informațiile cu caracter personal prezente în baza de date publica WHOIS a website-ului tău sunt în siguranță și nu pot fi utilizate de către hackeri și/sau spammeri în diferite acțiuni malițioase.',
    }
]

const RegisterDomains = () => {
    return (

        <Layout>

            {/*SEO*/}
            <SEO title="Înregistrare domenii și hosting. Verificare domeniu gratis"
                 description="Ești în căutare de DOMENII RO, COM sau EU? La SiteBunker.ro ai la dispoziție posibilități multiple de extensii disponibile și verificare domeniu preferat."
            />

            {/*Navbar*/}
            <Navbar/>

            {/*Register Domains Intro*/}
            <RegisterDomainsIntro/>

            {/*Register Benefits Section*/}
            <section id='register-domains-benefits'>
                <div className="container">
                    <div className="register-header text-center">
                        <h3 className='h3-title main-color mb-0'>Ai zeci de motive să înregistrezi domenii la
                            SiteBunker.ro!</h3>
                        <p className='main-color mb-0'>La SiteBunker.ro ai peste 100 de extensii disponibile pentru
                            înregistrare domenii.</p>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12">

                            <BenefitInfo svgIcon={<SvgSearch/>}
                                         title='Peste 100 de extensii'
                                         description='Tu alegi extensiile preferate pentru înregistrare domenii clasice precum .RO, .COM sau noile lansate .PRO și .CLOUD.'/>
                        </div>
                        <div className="col-md-6 col-12">
                            <BenefitInfo svgIcon={<SvgInfinite/>}
                                         title='Căutare avansată'
                                         description='Cu ajutorul motorului de căutare avansat, poți identifica numele domeniului și extensia preferată în doar câteva click-uri.'/>
                        </div>

                        <div className="col-md-6 col-12">
                            <BenefitInfo svgIcon={<SvgFile/>}
                                         title='Subdomenii nelimitate'
                                         description='Poți crea subdomenii nelimitate pentru fiecare landing page dedicat campaniilor, blogului sau magazinului tău online.'/>
                        </div>

                        <div className="col-md-6 col-12">
                            <BenefitInfo svgIcon={<SvgServerRefresh/>}
                                         title='Administrare intuitivă'
                                         description='Vei putea administra facil setările domeniului tău prin intermediul panoului de control ușor de utilizat de către oricine.'/>
                        </div>

                        <div className="col-md-6 col-12">
                            <BenefitInfo svgIcon={<SvgRedirect/>}
                                         title='Redirecționare facilă'
                                         description='Este simplu să redirecționezi orice domeniu către un alt domeniu, website sau pagină social media prin intermediul unui pachet de hosting.'/>
                        </div>

                        <div className="col-md-6 col-12">
                            <BenefitInfo svgIcon={<SvgLock/>}
                                         title='Domain Lock gratis'
                                         description='Prevenim transferurile accidentale sau neautorizate, astfel încât domeniul tău să fie în siguranță pe toată durata colaborării.'/>
                        </div>

                        <div className="col-md-6 col-12">
                            <BenefitInfo svgIcon={<SvgRefresh/>}
                                         title='Flexibilitate la reînnoire'
                                         description='Poți alege între reînnoirea automată sau manuală. Indiferent de preferință, ne asigurăm că primești la timp toate notificările.'/>
                        </div>

                        <div className="col-md-6 col-12">
                            <BenefitInfo svgIcon={<SvgFile/>}
                                         title='Confidentialitate'
                                         description='Pastreaza-ti datele personale departe de hackeri si spammeri. Alege Whois Privacy pentru doar 4€/an.'/>
                        </div>
                        <div className="col-md-6 col-12">
                            <BenefitInfo svgIcon={<SvgFile/>}
                                         title='Date personale protejate'
                                         description='Uită de hackeri și spammeri cu Whois Privacy, disponibil la costuri accesibile. Astfel, datele personale vor fi la adăpost față de orice atac. '/>
                        </div>
                    </div>

                </div>
            </section>

            {/*Accepted Payments*/}
            <PaymentsAccepted/>

            {/*Domain Tips*/}
            <DomainTips/>

            {/*Domains FAQS*/}
            <SimpleFAQS title='Îți stăm la dispoziție pentru orice întrebare'
                        subTitle='Citește răspunsurile pentru cele mai frecvente întrebări legate de înregistrare domenii sau contactează echipa SiteBunker.ro.'
                        questionsData={faqs}/>

            {/*Footer*/}
            <Footer/>
        </Layout>

    )
}

export default RegisterDomains
